import styles from "../../styles/widgets/button.scss";
import { _classes } from "../utilities/helpers";
import Link from "../widgets/Link";

const cl = _classes(styles);

Button.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  tabIndex: PropTypes.string,
  ariaLabel: PropTypes.string,
  link: PropTypes.string,
};

Button.defaultProps = {
  className: "",
  text: "",
  link: "",
  onClick: () => null,
  tabIndex: "0",
};

export default function Button({
  text,
  link,
  onClick,
  className,
  tabIndex,
  ariaLabel,
}) {
  //We need an even number of divs to create the background correctly
  const numDivs = text.length % 2 === 0 ? text.length + 6 : text.length + 7;

  const renderBkg = () => {
    return (
      <>
        <div className={cl("btn-background")}>
          {Array.from(Array(numDivs).keys()).map((item, index) => {
            return (
              <div key={index}>
                <span data-bkg={item} className="inner"></span>
              </div>
            );
          })}
        </div>

        <span className={cl("text")}>{text}</span>
      </>
    );
  };
  return (
    <>
      {link ? (
        <Link className={`${cl("_")} ${className}`} path={link}>
          {renderBkg()}
        </Link>
      ) : (
        <button
          className={`${cl("_")} ${className}`}
          onClick={onClick}
          aria-label={ariaLabel}
          tabIndex={tabIndex}
        >
          {renderBkg()}
        </button>
      )}
    </>
  );
}
