var pug = require("!../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (development, hash, htmlWebpackPlugin) {pug_html = pug_html + "\u003C!DOCTYPE html\u003E\u003Chtml lang=\"en\"\u003E\u003Chead\u003E\u003Cmeta charset=\"utf-8\"\u003E\u003Cmeta http-equiv=\"X-UA-Compatible\" content=\"IE=edge,chrome=1\"\u003E\u003Cmeta name=\"viewport\" content=\"width=device-width, initial-scale=1, user-scalable=no\"\u003E\u003Cscript async=\"\" src=\"https:\u002F\u002Fwww.googletagmanager.com\u002Fgtag\u002Fjs?id=G-00TBNSGB8N\"\u003E\u003C\u002Fscript\u003E\u003Cscript\u003Ewindow.dataLayer = window.dataLayer || [];\nfunction gtag() {\n  dataLayer.push(arguments);\n}\ngtag('js', new Date());\ngtag('config', 'G-00TBNSGB8N');\n\n\u003C\u002Fscript\u003E\u003Cscript\u003E(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\nnew Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\nj=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n'https:\u002F\u002Fwww.googletagmanager.com\u002Fgtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n})(window,document,'script','dataLayer','GTM-NWQRMNNS');\n\n\u003C\u002Fscript\u003E\u003Clink rel=\"stylesheet\" href=\"https:\u002F\u002Fuse.typekit.net\u002Fuzt8fmn.css\"\u003E";
if (!(development)) {
// iterate htmlWebpackPlugin.files.css
;(function(){
  var $$obj = htmlWebpackPlugin.files.css;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var css = $$obj[pug_index0];
pug_html = pug_html + "\u003Clink" + (pug.attr("href", css, true, true)+" rel=\"stylesheet\" type=\"text\u002Fcss\"") + "\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var css = $$obj[pug_index0];
pug_html = pug_html + "\u003Clink" + (pug.attr("href", css, true, true)+" rel=\"stylesheet\" type=\"text\u002Fcss\"") + "\u003E";
    }
  }
}).call(this);

}
pug_html = pug_html + "\u003C\u002Fhead\u003E\u003Cbody\u003E\u003Cdiv class=\"wrapper\" id=\"app\"\u003E\u003C\u002Fdiv\u003E\u003Cnoscript\u003E\u003Ciframe src=\"https:\u002F\u002Fwww.googletagmanager.com\u002Fns.html?id=GTM-NWQRMNNS\" height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\"\u003E\u003C\u002Fiframe\u003E\u003C\u002Fnoscript\u003E";
if (development) {
pug_html = pug_html + "\u003Cscript" + (pug.attr("src", '/main' + hash + '.js', true, true)+" type=\"text\u002Fjavascript\""+pug.attr("async", true, true, true)) + "\u003E\u003C\u002Fscript\u003E";
}
else {
// iterate htmlWebpackPlugin.files.js
;(function(){
  var $$obj = htmlWebpackPlugin.files.js;
  if ('number' == typeof $$obj.length) {
      for (var pug_index1 = 0, $$l = $$obj.length; pug_index1 < $$l; pug_index1++) {
        var js = $$obj[pug_index1];
pug_html = pug_html + "\u003Cscript" + (" type=\"text\u002Fjavascript\""+pug.attr("src", js, true, true)+pug.attr("async", true, true, true)) + "\u003E\u003C\u002Fscript\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index1 in $$obj) {
      $$l++;
      var js = $$obj[pug_index1];
pug_html = pug_html + "\u003Cscript" + (" type=\"text\u002Fjavascript\""+pug.attr("src", js, true, true)+pug.attr("async", true, true, true)) + "\u003E\u003C\u002Fscript\u003E";
    }
  }
}).call(this);

}
pug_html = pug_html + "\u003C\u002Fbody\u003E\u003C\u002Fhtml\u003E";}.call(this,"development" in locals_for_with?locals_for_with.development:typeof development!=="undefined"?development:undefined,"hash" in locals_for_with?locals_for_with.hash:typeof hash!=="undefined"?hash:undefined,"htmlWebpackPlugin" in locals_for_with?locals_for_with.htmlWebpackPlugin:typeof htmlWebpackPlugin!=="undefined"?htmlWebpackPlugin:undefined));;return pug_html;};
module.exports = template;