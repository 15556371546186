export const Close = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="29px" height="28px">
      <path
        fillRule="evenodd"
        fill="rgb(255, 255, 255)"
        d="M0.711,26.374 L26.874,0.211 L28.289,1.626 L2.126,27.788 L0.711,26.374 Z"
      />
      <path
        fillRule="evenodd"
        fill="rgb(255, 255, 255)"
        d="M2.126,0.211 L28.289,26.374 L26.874,27.788 L0.711,1.626 L2.126,0.211 Z"
      />
    </svg>
  );
};
