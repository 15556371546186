import { _classes } from "../utilities/helpers";
import styles from "../../styles/components/RoomTypeCard.scss";
// Cyclical import error:
// TODO::: Mention to devs
import Image from "./../widgets/Image";
import Link from "./../widgets/Link";
const cl = _classes(styles);

RoomTypeCard.propTypes = {
  className: PropTypes.string,
  room: PropTypes.object,
  slide: PropTypes.object,
};
export default function RoomTypeCard({ slide }) {
  return (
    <div className={cl("_")}>
      <div className={cl("room-type-img-wrapper")}>
        <Image alt="" src={slide.image1} />
      </div>
      <div className={cl("room-type-card-text-section")}>
        <span className={cl("room-type-label")}>{slide.linktitle}</span>
        <Link
          className={cl("link")}
          brandLink
          title={slide.h2 ? slide.h2 : "Learn More"}
          path={slide.path}
        />
      </div>
    </div>
  );
}
