import React, { useState } from "react";
import { LazyBoundary, ImportedController } from "react-imported-component";
import browser from "bowser";
import InvalidBrowser from "./utilities/InvalidBrowser/InvalidBrowser.js";
import Footer from "./partials/Footer";
import Header from "./partials/header-components/Header";
import APP_CONFIG from "./config/appConfig";
import Routes from "./containers/Routes.js";
import { useEffect } from "react";
import useAppState from "./providers/AppProvider.js";
import { _classes } from "./utilities/helpers";
import styles from "../styles/containers/_app.scss";
import CookiePopup from "./partials/CookiePopup.js";
import PreLoader from "./components/PreLoader.js";
const cl = _classes(styles);
App.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
};

export default function App({ location }) {
  const {
    byPath,
    pathname,
    setPathname,
    homeSectionsId,
    pagesContent,
    loading,
    setLoading,
  } = useAppState();
  // const [loading, setLoading] = useState(true);
  useEffect(() => {
    loadingDisplay();
    pageChangeRoutine();
    // setTimeout(hideLoader, APP_CONFIG.loadingDuration);
  }, []);

  useEffect(() => {
    trackPageView(location.pathname);
    pageChangeRoutine();
  }, [location.pathname, location.search]);

  useEffect(() => {
    location.pathname !== pathname && setPathname(location.pathname);
  }, [location.pathname]);

  const loadingDisplay = () =>
    setTimeout(() => {
      setLoading(false);
    }, 1700);
  // const hideLoader = () =>
  //   (document.querySelector(".site-loader").className +=
  //     " site-loader--loaded");

  const isValidBrowser = () =>
    !(browser.msie && parseInt(browser.version) < 11);

  const pageChangeRoutine = () => {
    const page = byPath(location.pathname);
    document.title = page.pagetitle || byPath("/").pagetitle;
  };

  const trackPageView = (page) => {
    const { title } = document;
    if (window.ga) {
      ga("send", "pageview", { page, title });
    }
  };

  if (!isValidBrowser()) {
    return <InvalidBrowser />;
  }

  return (
    <ImportedController>
      <PreLoader hide={!loading ? true : false} />
      <div className={cl("app_container")}>
        <Header />
        <Routes />
        <Footer />
        <CookiePopup />
      </div>
    </ImportedController>
  );
}
