import styles from "../../styles/partials/CookiePopup.scss";
import { useState } from "react";
import useAppState from "../providers/AppProvider";
import React from "react";
import Button2 from "../widgets/Button2";
import { _classes } from "../utilities/helpers";
const cl = _classes(styles);

CookiePopup.propTypes = {};

function CookiePopup() {
  const { byTemplate } = useAppState();
  const page = byTemplate("footer")[0];

  const [visible, setVisibility] = useState(true);

  const toggleVisible = () => {
    setVisibility(false);
    window.localStorage.setItem("cookiePopup", true);
  };

  if (page?.blurb1 && !window.localStorage?.cookiePopup) {
    return (
      <div
        className={cl([
          "cookiePopup",
          visible ? styles.cookiePopup_visible : "",
        ])}
      >
        <div className={cl("cookiePopup__inner")}>
          <div
            className={cl("cookiePopup__blurb")}
            dangerouslySetInnerHTML={{ __html: page?.blurb1 }}
          />
          <Button2
            className={cl("cookiePopup__button")}
            onClick={toggleVisible}
            text="GOT IT"
          />
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default CookiePopup;
