/******************************************************************************\
 CONFIG: COMPONENT DECLARATION, PROPTYPES, DEFAULT PROPS, & COMPONENT STATE
\******************************************************************************/
const _num = [1, 2, 3, 4, 5, 6, 7, 8, 9];
const num_str = ["1", "2", "3", "4", "5", "6", "7", "8", "9"];

Image.propTypes = {
  align: PropTypes.oneOf([..._num, ...num_str]).isRequired,
  alt: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string.isRequired,
  fixedAspectRatio: PropTypes.bool.isRequired,
  src: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["background", "element"]).isRequired,
  imagerole: PropTypes.string,
  figureprops: PropTypes.object,
  caption: PropTypes.string,
  preventBgRender: PropTypes.bool,
};
//default props
Image.defaultProps = {
  align: 5,
  className: "",
  fixedAspectRatio: true,
  type: "background",
  imagerole: "presentation",
  figureprops: {},
  caption: "",
  preventBgRender: false,
};

function Image(props) {
  const {
    align,
    alt,
    className,
    src,
    type,
    imagerole,
    figureprops,
    preventBgRender,
  } = props;
  const isbg = type === "background";
  const imgbasestyles = isbg
    ? {
        position: "absolute",
        opacity: 0,
        maxWidth: "100%",
      }
    : {
        maxWidth: "100%",
      };
  return (
    <figure
      className={`image image--${type} image--aligned-${align} ${className}`}
      {...figureprops}
      style={{
        backgroundImage: !preventBgRender && `url('${src}')`,
        position: "relative",
        margin: 0,
      }}
    >
      <img src={src} alt={alt} style={imgbasestyles} role={imagerole} />
      {props.caption && props.caption.trim() !== "" && (
        <figcaption>{props.caption}</figcaption>
      )}
    </figure>
  );
}

export default Image;
