import { useEffect, useRef } from "react";
import Slick from "react-slick";
import Image from "widgets/Image";
import RoomTypeCard from "../components/RoomTypeCard";
import VideoPlayer from "./VideoPlayer";
// when using the carousel in your component:
// wrap the carousel in a container
// it will inherit the size of it's wrapper :)

Carousel.propTypes = {
  slides: PropTypes.array,
  settings: PropTypes.object,
  className: PropTypes.string,
  keyboardControl: PropTypes.bool,
  videoLink: PropTypes.string,
};
Carousel.defaultProps = {
  settings: {
    arrows: true,
    dots: false,
    keyboardControl: false,
  },
};

function Carousel(props) {
  const { slides, className, settings, keyboardControl, videoLink, roomType } =
    props;
  const slick = useRef();

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, []);

  const imageComponentFor = (slide) => {
    const imgProps = {
      align: slide.image1_anchor,
      src: slide.image1,
      alt: slide.image1_alt_text,
    };
    return <Image {...imgProps} />;
  };

  const handleKeyDown = (e) => {
    if (keyboardControl && slick) {
      if (
        !document.activeElement ||
        document.activeElement.tagName !== "INPUT"
      ) {
        if (e.key === "ArrowLeft" || e.key === "Left") {
          slick?.current?.slickPrev();
        } else if (e.key === "ArrowRight" || e.key === "Right") {
          slick?.current?.slickNext();
        }
      }
    }
  };

  if (slides?.length === 1 && !videoLink) {
    return (
      <div
        className={`carousel-with-one-image ${className}`}
        style={{ padding: 0 }}
      >
        {imageComponentFor(slides[0])}
      </div>
    );
  } else if (videoLink) {
    const videoStyles = {
      width: "100%",
      position: "absolute",
      left: 0,
      top: 0,
      boxSizing: "border-box",
      objectFit: "cover",
      height: "100%",
    };

    const containerStyles = {
      position: "relative",
      width: "100%",
      height: "100% !important",
      paddingTop: "56.25%",
      boxSizing: "border-box",
    };
    return (
      <VideoPlayer
        containerClassName={"property-feature-video-container"}
        styles={videoStyles}
        containerStyles={containerStyles}
        controls={false}
        src={videoLink}
      />
    );
  }

  if (roomType) {
    return (
      <div className={`carousel ${className}`}>
        <Slick ref={slick} {...settings}>
          {slides?.map((slide, i) => {
            return <RoomTypeCard key={i} slide={slide} />;
          })}
        </Slick>
      </div>
    );
  }

  return (
    <div className={`carousel ${className}`}>
      <Slick ref={slick} {...settings}>
        {slides?.map((slide, i) => {
          return <div key={i}>{imageComponentFor(slide)}</div>;
        })}
      </Slick>
    </div>
  );
}

export default Carousel;
