import styles from "./ErrorMessage.scss";
import { _classes } from "../../utilities/helpers";

const cl = _classes(styles);

ErrorMessage.propTypes = {
  error: PropTypes.object,
  color: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
};

ErrorMessage.defaultProps = {
  className: "",
};

export default function ErrorMessage({
  error,
  color,
  id,
  fontSize,
  className,
}) {
  if (error) {
    const message = error.message || "Error";

    return (
      <span
        className={cl(["root", className])}
        style={{ color, fontSize }}
        id={id}
      >
        {message}
      </span>
    );
  }

  return null;
}
