export const InstagramIcon = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 427 427"
      height={height || "20"}
      width={width || "20"}
      stroke="rgb(255, 255, 255)"
      fill="rgb(255, 255, 255)"
    >
      <path
        className="st0"
        d="M213.5 38.5c57 0 63.8.2 86.3 1.2 20.8 1 32.1 4.4 39.6 7.4 10 3.9 17.1 8.5 24.5 16 7.5 7.5 12.1 14.6 16 24.5 2.9 7.5 6.4 18.8 7.4 39.6 1 22.5 1.2 29.3 1.2 86.3s-.2 63.8-1.2 86.3c-1 20.8-4.4 32.1-7.4 39.6-3.9 10-8.5 17.1-16 24.5-7.5 7.5-14.6 12.1-24.5 16-7.5 2.9-18.8 6.4-39.6 7.3-22.5 1-29.3 1.2-86.3 1.2s-63.8-.2-86.3-1.2c-20.8-.9-32.1-4.4-39.6-7.3-10-3.9-17.1-8.5-24.5-16-7.5-7.5-12.1-14.6-16-24.5-2.9-7.5-6.4-18.8-7.4-39.6-1-22.5-1.2-29.3-1.2-86.3s.2-63.8 1.2-86.3c.9-20.8 4.4-32.1 7.4-39.6 3.9-10 8.5-17.1 16-24.5 7.5-7.5 14.6-12.1 24.5-16 7.5-2.9 18.8-6.4 39.6-7.4 22.5-1 29.3-1.2 86.3-1.2m0-38.5c-58 0-65.3.2-88 1.3-22.7 1-38.2 4.6-51.8 9.9-14 5.5-25.9 12.8-37.8 24.6C24 47.7 16.7 59.6 11.2 73.6c-5.3 13.6-8.9 29.1-9.9 51.8-1 22.8-1.3 30-1.3 88s.2 65.3 1.3 88c1 22.7 4.6 38.2 9.9 51.8 5.5 14 12.8 25.9 24.6 37.8 11.9 11.9 23.8 19.2 37.8 24.6 13.6 5.3 29.1 8.9 51.8 9.9 22.8 1 30 1.3 88 1.3s65.3-.2 88-1.3c22.7-1 38.2-4.6 51.8-9.9 14-5.5 25.9-12.8 37.8-24.6 11.9-11.9 19.2-23.8 24.6-37.8 5.3-13.6 8.9-29.1 9.9-51.8 1-22.8 1.3-30 1.3-88s-.2-65.3-1.3-88c-1-22.7-4.6-38.2-9.9-51.8-5.5-14-12.8-25.9-24.6-37.8-11.9-11.9-23.8-19.2-37.8-24.6-13.6-5.3-29.1-8.9-51.8-9.9C278.7.2 271.5 0 213.5 0"
      />
      <path
        className="st0"
        d="M213.5 103.9c-60.5 0-109.6 49.1-109.6 109.6S153 323.1 213.5 323.1 323.1 274 323.1 213.5c0-60.6-49.1-109.6-109.6-109.6m0 180.8c-39.3 0-71.2-31.9-71.2-71.2s31.9-71.2 71.2-71.2 71.2 31.9 71.2 71.2-31.9 71.2-71.2 71.2M353.1 99.5c0 14.1-11.5 25.6-25.6 25.6s-25.6-11.5-25.6-25.6c0-14.2 11.5-25.6 25.6-25.6s25.6 11.5 25.6 25.6"
      />
    </svg>
  );
};
