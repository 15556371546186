import styles from "../../styles/components/FooterGalleryItem.scss";
import { _classes } from "../utilities/helpers";
import { Link } from "../widgets";
import VideoPlayer from "../widgets/VideoPlayer";

const cl = _classes(styles);

FooterGalleryItem.propTypes = {
  content: PropTypes.string,
  videoLink: PropTypes.string,
  link: PropTypes.string,
};

function FooterGalleryItem({ src, videoLink, link }) {
  const videoLinkPresent = videoLink !== null && videoLink !== "";

  return (
    <Link className={cl("item")} path={link} target="_blank">
      {videoLinkPresent ? (
        <VideoPlayer
          height={200}
          width={200}
          muted
          controls={false}
          src={videoLink}
          backgroundImage={src}
        />
      ) : (
        <img src={src} alt="grid image" />
      )}
    </Link>
  );
}

export default FooterGalleryItem;
