import { useEffect, useLayoutEffect, useState } from "react";
import { LazyBoundary, useIsClientPhase } from "react-imported-component";
import { Switch, Route, Redirect } from "react-router-dom";
import useAppState from "../providers/AppProvider";
import ErrorBoundary from "../utilities/ErrorBoundary";
import { AnimatePresence, motion } from "../utilities/FramerMotion";
import viewsPaths from "../utilities/viewPaths";
import React from "react";
import { useCurrentBasePageContent } from "../utilities/helpers";

Routes.propTypes = {};

const getElementTop = (id) => {
  if (!id) return 0;
  const top = document?.querySelector(`${id}`)?.getBoundingClientRect()?.top;
  return top;
};

export default function Routes() {
  const {
    content,
    location,
    pagesContent,
    homeSectionsId,
    homepageSections,
    history,
  } = useAppState();
  // const page = pagesContent.where("path").is(location.pathname).data;
  const hasURLHash = location.hash;
  // || page.parentid == homeSectionsId || location.pathname == "/"

  const [pageTransitionState, setPageTransitionState] = useState("");
  const [animationDone, setAnimationDone] = useState(false);
  const [routes, setRoutes] = useState([]);

  const { basePageContent: propertyPage } = useCurrentBasePageContent();

  const pushHash = (hash) => {
    history.push({
      hash,
      pathname: propertyPage.path,
    });
    // trigger push
    window.dispatchEvent(new HashChangeEvent("hashchange"));
  };

  useEffect(() => {
    window.scrollTo({
      behavior: !pageTransitionState ? "instant" : "smooth",
      top: !pageTransitionState
        ? 0
        : location.hash
        ? getElementTop(location.hash) - (!hasURLHash ? 0 : 120)
        : 0,
    });

    return () => {
      setPageTransitionState(location.key);
    };
  }, [pageTransitionState]);

  // empty useEffect
  useEffect(() => {
    // URL changed, update the state
    setPageTransitionState(location.key);
  }, [location.path]);

  // empty useEffect
  useEffect(() => {
    setRoutes(createBaseRoutes());
  }, []);

  const createBaseRoutes = () => {
    const donotroute = ["footer", "header", "restaurant"];

    return Object.keys(content)
      .map((key) => {
        const page = content[key];
        const template = page.template.toLowerCase();
        const Component = viewsPaths[`${template}`] || viewsPaths["default"];

        return (
          !donotroute.includes(page.template) && (
            <Route
              exact
              key={page.id}
              path={[page.path, ...homepageSections.map((s) => s.path)]}
              render={(props) => (
                <LazyBoundary fallback={<div />}>
                  <ErrorBoundary>
                    <Component
                      page={page}
                      {...props}
                      pageTransitionState={pageTransitionState}
                    />
                  </ErrorBoundary>
                </LazyBoundary>
              )}
            />
          )
        );
      })
      .concat(
        <Route path={"/"} key="elseAll" render={() => <Redirect to="/" />} />
      );
  };

  return (
    // <main style={{ minHeight: "120vh" }}>
    <main>
      <AnimatePresence key={location.key} initial={{ opacity: 1 }}>
        <motion.div
          // key={location.pathname}
          key={location.key}
          onAnimationComplete={() => {
            if (!location?.hash) {
              return;
            }
            let hashElId = "";
            try {
              hashElId =
                document && document?.querySelector?.(location?.hash)?.id;

              // console.log("hashElId::", pageTransitionState, "#"+hashElId,location.hash);

              !animationDone && pushHash(hashElId);
              // || location.hash !== "#"+hashElId &&
              setAnimationDone(true);
            } catch (error) {
              setAnimationDone(true);

              console.log("error::", error);
            }
          }}
          initial={{ opacity: 0, y: 0 }}
          animate={{ opacity: 1, y: 0 }}
          // animate={{
          //   opacity: !hasURLHash || animationDone ? 1 : 0,
          //   transition: { delay: 0.2, opacity: { duration: 0.8 } },
          // }}
          // exit with a duration of 2s
          exit={{ opacity: 1, y: 0 }}
          transition={{ ease: "easeInOut", duration: 0, delay: 0 }}
          // className="page"
          // layout="position"
          onAnimationStart={() => {
            const page = pagesContent.where("path").is(location.pathname).data;
            if (page.parentid != homeSectionsId && page.parentid != 0) {
              page.path == window.location.pathname &&
                // please don't remove, needed for all the weird scrolling bugs.
                // Resetting the scroll position to top of page fixes all the said scrolling bugs, mmhmm
                setPageTransitionState("");
            }
          }}
        >
          <Switch location={location}>{routes}</Switch>
        </motion.div>
      </AnimatePresence>
    </main>
  );
}
