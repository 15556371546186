import Link from "./Link";
import styles from "../../styles/widgets/_cta.scss";

CTA.propTypes = {
  link: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  tabIndex: PropTypes.string,
};

CTA.defaultProps = {
  link: "",
  text: "",
  onClick: () => null,
  className: "",
};

export default function CTA({ link, text, onClick, className, tabIndex }) {
  return (
    <Link
      tabIndex={tabIndex}
      className={`${className} ${styles.cta}`}
      path={link}
      ariaLabel={text}
      onClick={() => {
        onClick();
      }}
    >
      {text}
    </Link>
  );
}

