import { Controller } from "react-hook-form/dist/index.ie11";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import Label from "../Label/Label";
import { useFormContext } from "../FormProvider";
import { v4 as uuidv4 } from "uuid";
import { _classes } from "../../utilities/helpers";
import styles from "./TextareaInput.scss";

const UUID = uuidv4();

const cl = _classes(styles);

TextareaInput.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  rules: PropTypes.object,
  defaultValue: PropTypes.string,
  className: PropTypes.string,
  textAreaClassName: PropTypes.string,
  errorClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  disabled: PropTypes.bool,
};

TextareaInput.defaultProps = {
  name: "input",
  label: "Label",
  placeholder: "Your message here...",
  defaultValue: "",
  className: "",
  textAreaClassName: "",
  errorClassName: "",
  labelClassName: "",
};

export default function TextareaInput({
  name,
  placeholder,
  label,
  rules,
  className,
  defaultValue,
  disabled,
  textAreaClassName,
  errorClassName,
  labelClassName,
}) {
  const { control, errors } = useFormContext();
  const id = `${name}__${UUID}`;

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({ onChange, value = "" }) => (
        <div className={cl(["root", className])}>
          <Label
            value={label}
            htmlFor={id}
            className={cl(["label", labelClassName])}
          />
          <div className={cl("control")}>
            <textarea
              id={id}
              disabled={disabled}
              onChange={onChange}
              name={name}
              value={value}
              placeholder={placeholder}
              className={cl(["textarea", textAreaClassName])}
            ></textarea>
          </div>
          <div className={cl("error")}>
            <ErrorMessage error={errors[name]} className={errorClassName} />
          </div>
        </div>
      )}
    />
  );
}
