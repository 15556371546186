import { _classes } from "../utilities/helpers";
import styles from "../../styles/components/DashedBorder.scss";
import { useEffect, useState } from "react";
const cl = _classes(styles);

DashedBorder.propTypes = {};
export default function DashedBorder({ children }) {
  const [width, setWidth] = useState(0);
  useEffect(() => {
    handleResize();
  }, []);
  function handleResize() {
    window.innerWidth < 1000 ? setWidth(0) : setWidth(1);
  }
  const dashLength = width === 0 ? 54 : 108;

  return (
    <div className={cl("_")}>
      <div className={cl("border")}>
        {Array.from(Array(dashLength).keys()).map((e, i) => (
          <div key={i} className={cl("border__dash")} />
        ))}
      </div>
      {children}
    </div>
  );
}
