import {
  StandardInput,
  FormProvider,
  FancySubmitInput,
  SelectInput,
  DayPickerInput,
  useFormContext,
} from "../../form";
import {
  range,
  _classes,
  useCurrentBasePageContent,
} from "../../utilities/helpers";
import styles from "../../../styles/components/header-components/NavBookingWidgetForm.scss";
import { useAppState } from "../../providers";
import moment from "moment";
import { useEffect, useState } from "react";

const cl = _classes(styles);

NavBookingWidgetForm.propTypes = {};
NavBookingWidgetForm.defaultProps = {};
export default function NavBookingWidgetForm() {
  const { byTemplate, byId, isHotel } = useAppState();
  const [selectedLocation, setSelectedLocation] = useState(null);
  const transformMalformedDate = (date) => {
    return moment(date, "MM/DD/YYYY").toDate();
  };

  const pages = byTemplate("property_settings");
  const {
    basePageContent: propertyPage,
    match: { url: isPropertyPage },
  } = useCurrentBasePageContent();

  const locationOptions = pages
    .filter((page) => page.h2 && page.h3)
    .map((page) => {
      const parent = byId(page.parentid);
      const dateIsOlderThanToday = (date) => {
        const parsedDate = moment(date, "M-DD-YYYY").startOf("day");
        const today = moment().startOf("day");
        return parsedDate.isBefore(today);
      };

      const openingDate = dateIsOlderThanToday(page.h4)
        ? moment.now()
        : moment(page.h4, "MM/DD/YYYY").format("MM/DD/YYYY");

      // console.log(
      //   "%c openingDate / page.h4 / dateIsOlderThanToday",
      //   "color: yellow;",
      //   openingDate,
      //   page.h4,
      //   dateIsOlderThanToday(page.h4)
      // );
      return {
        label: parent.h1,
        value: {
          chain: page.h3,
          hotel: page.h2,
          openingDate: openingDate,
        },
        parentId: parent.id,
        path: parent.path,
      };
    });

  const defaultPropertyObj = {
    label: "",
    value: {
      chain: "10634",
      hotel: "",
      openingDate: getOpeningDate(),
    },
    parentId: null,
    path: "/our-locations",
  };

  function getOpeningDate() {
    return locationOptions.find((option) => {
      return option.value.hotel === selectedLocation?.hotel
        ? moment(option.value.openingDate)
        : moment.now();
    });
  }

  let defaultLocationOpt = locationOptions.find(
    (opt) => opt.parentId === propertyPage.id
  );

  if (!isPropertyPage) {
    defaultLocationOpt = defaultPropertyObj;
  }
  const handleLocationChange = (opt) => {
    setSelectedLocation(opt.value);
  };

  const onSubmit = (data) => {
    return new Promise((resolve) => {
      const base = "https://be.synxis.com/?";
      const params = new URLSearchParams({
        adult: data.adults,
        child: data.children,
        chain: data.location.chain,
        hotel: data.location.hotel,
        arrive: data.arrive,
        depart: data.depart,
        promo: data.promo,
        level: "hotel",
        currency: "USD",
        locale: "en-US",
        rooms: 1,
      });

      window.open(base + params.toString(), "_blank");
      window.focus();

      resolve({});
    });
  };

  return (
    <div className={cl("root")}>
      <FormProvider onSubmit={onSubmit} showSuccess={false}>
        <div className={cl("row")}>
          <SelectInput
            defaultValue={defaultLocationOpt}
            name="location"
            label="Location*"
            placeholder="Select a location"
            options={locationOptions}
            rules={{ required: "Location is required" }}
            middleware={handleLocationChange}
          />
        </div>
        <RenderDatePickerFields defaultLocationValue={defaultLocationOpt} />
        <div className={cl("row")}>
          <SelectInput
            name="adults"
            label="# Adults"
            options={range(3, 1).map((int) => ({ label: int, value: int }))}
          />
          <SelectInput
            name="children"
            label={"# Children"}
            options={range(3, 1).map((int) => ({ label: int, value: int }))}
          />
        </div>
        <div className={cl("row")}>
          <StandardInput name="promo" label="Promo" labelVisible />
        </div>

        <FancySubmitInput text={"Check Availability"} />
      </FormProvider>
    </div>
  );
}

function RenderDatePickerFields({ defaultLocationValue }) {
  const { watch, setValue } = useFormContext();

  const location = watch("location", defaultLocationValue);
  const arriveDate = watch("arrive");

  const openingDate = location
    ? moment(location.openingDate)
    : moment(new Date().toISOString().slice(0, 10));

  useEffect(() => {
    let updatedOpeningDate = location
      ? moment(location.openingDate)
      : moment(arriveDate);

    const updatedDepart = moment(updatedOpeningDate).add(1, "days");
    setValue("arrive", updatedOpeningDate?.toDate());
    setValue("depart", updatedDepart?.toDate());
  }, [location]);

  const defaultArrive = moment(new Date()).isSameOrBefore(openingDate.toDate())
    ? openingDate
    : moment();

  const defaultDepart = moment(defaultArrive).add(1, "days");

  const disabled = [
    {
      before: defaultArrive.toDate(),
    },
  ];

  return (
    <div className={cl("row")}>
      <DayPickerInput
        dayPickerClassName={cl("daypicker")}
        defaultValue={defaultArrive.toDate().toString()}
        name="arrive"
        label={"Arrive*"}
        rules={{ required: "Arrive is required" }}
        showOverlay={true}
        align="left"
        dayPickerProps={{
          fromYear: moment().year(),
          fromMonth: defaultArrive.toDate(),
          disabled,
        }}
      />

      <DayPickerInput
        dayPickerClassName={cl("daypicker")}
        defaultValue={defaultDepart.toDate().toString()}
        name="depart"
        label={"Depart*"}
        rules={{ required: "Depart is required" }}
        showOverlay={false}
        align="right"
        dayPickerProps={{
          fromYear: moment().year(),
          fromMonth: defaultArrive.toDate(),
          disabled: [{ before: moment(arriveDate).add(1, "day").toDate() }],
        }}
      />
    </div>
  );
}
