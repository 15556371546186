import { _classes } from "../../utilities/helpers";
import styles from "./FancySubmitInput.scss";

const cl = _classes(styles);

SubmitInput.propTypes = {
  text: PropTypes.string,
  ariaLabel: PropTypes.string,
};

SubmitInput.defaultProps = {
  text: "Submit",
};

export default function SubmitInput({ text, ariaLabel, ...props }) {
  const numDivs = text.length % 2 === 0 ? text.length + 6 : text.length + 7;
  return (
    <div className={cl("root")}>
      <button className={cl("button")} type="submit" aria-label={ariaLabel} {...props}>
        <div className={cl("background")}>
          {Array.from(Array(numDivs).keys()).map((item, index) => (
            <div key={index} className={cl("block")}>
              <span data-bkg={item} className="inner"></span>
            </div>
          ))}
        </div>

        <span className={cl("text")}>{text}</span>
      </button>
    </div>
  );
}
