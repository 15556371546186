import DashedBorder from "../components/DashedBorder";
import FooterGallery from "./FooterGallery";
import FooterPropertyBranding from "./FooterPropertyBranding";
import { _classes, useCurrentBasePageContent } from "../utilities/helpers";
import styles from "../../styles/partials/ExtendedFooter.scss";
import { useAppState } from "../providers";
const cl = _classes(styles);

ExtendedFooter.propTypes = {};

export default function ExtendedFooter() {
  const { byTemplate } = useAppState();
  const {
    basePageContent,
    match: { url: isPropertyPage },
  } = useCurrentBasePageContent();
  const footerContent = byTemplate("footer")[0];
  const footerGalleryItems = footerContent?.fieldgroup2;
  //TODO: When on a property page, override the contact and address information with the property's information
  const contactInfo = {
    address1: basePageContent?.address1 || "Address 1",
    address2: basePageContent?.address2 || "Address 2",
    postal_code: basePageContent?.postal_code || "Postal Code",
    phone: basePageContent?.phone || "555-555-5555",
    maps_url: basePageContent?.h4 || "https://www.google.com/maps",
  };

  const ExtendedFooterContent = () =>
    !isPropertyPage ? (
      <FooterGallery
        galleryMediaLink={footerContent?.h3}
        galleryMediaTitle={footerContent?.h6}
        galleryContent={footerGalleryItems}
      />
    ) : (
      <FooterPropertyBranding pageContent={contactInfo} />
    );

  return (
    <div className={cl("container")}>
      <DashedBorder />
      <ExtendedFooterContent />
    </div>
  );
}
