import { useEffect } from "react";
import Hamburger from "../../components/Hamburger";
import styles from "../../../styles/partials/header-components/Header.scss";
import {
  _classes,
  useCurrentBasePageContent,
  returnPropertyBySlug,
} from "../../utilities/helpers";
import useAppState from "../../providers/AppProvider.js";
import { useViewportContext } from "../../providers";
import NavBookingWidget from "../header-components/NavBookingWidget";
import { Link } from "../../widgets";
import Logo from "../header-components/Logo";
import Menu from "./Menu";
import Button2 from "../../widgets/Button2";
import { AnimatePresence, motion } from "framer-motion";
import { element } from "prop-types";

const cl = _classes(styles);

function Header() {
  const {
    byTemplate,
    setBookNowModal,
    bookNowModal,
    isHotel,
    childrenByPath,
    pathname,
    userScrolling,
    setUserScrolling,
    history,
    pagesContent,
  } = useAppState();

  const header = byTemplate("header")[0];
  const propertyPages = byTemplate("property");
  const homePage = byTemplate("home")[0];

  // Determine the current property page
  const currPropertyPage = returnPropertyBySlug(propertyPages, pathname)[0];

  // Logo related items
  const propertyLogo = currPropertyPage?.image2;
  const brandLogo = homePage?.image6;

  // Set the logo and link path to be used
  const logo = isHotel ? propertyLogo : brandLogo;
  const logoLink = isHotel ? currPropertyPage?.path : "/";

  const isPropertyLandingPage = pathname === currPropertyPage?.path;
  const isHome = pathname === "/" ? true : false;
  const isLandingPage = isPropertyLandingPage || isHome ? true : false;
  const showLogo = userScrolling || !isLandingPage ? true : false;

  const { basePageContent: propertyPage } = useCurrentBasePageContent();

  // Handles hash change
  const pushHash = (hash) => {
    history.push({
      hash,
      pathname: propertyPage.path,
    });

    // Trigger push
    window.dispatchEvent(new HashChangeEvent("hashchange"));
  };

  // Property nav items handler
  const propertyNavItems = () => {
    // TODO: consider using content from CMS for these properties
    const overviewSubMenuItem = {
      linktitle: "Overview",
      title: "Overview",
      path: null,
      slug: null,
      onClick: (e, toggleMenu) => {
        pushHash("about");
        toggleMenu();
      },
    };
    const homePage = byTemplate("home")[0];
    homePage["title"] = "Le Petit Pali";

    const aboutTheBrandSubMenuItem = {
      linktitle: "About the Brand",
      title: "About the Brand",
      path: "/",
      slug: "/",
    };

    const propertyPageLocationNav = {
      ...childrenByPath("/locations")
        .filter(
          (element) => element.checkbox1 === false
          // rename element.checkbox1 to "coming_soon":
        )
        .map((element) => ({
          ...element,
          linktitle: (
            <>
              {/* Main nav uses TITLE, not linktitle. Title is a custom attribute is a custom field in the CMS, not the same as the page content object's "title"*/}
              {/* see example: https://lepetitpali.ideaworkwidgets.com/admin/content/23 */}
              {element.title}
              {/* {element.checkbox1 && (
              <span className={cl("coming-soon")}>(Coming Soon)</span>
            )} */}
            </>
          ),
        })),
    };

    // Constructing the navigation items
    let propertyNav = [
      ...Object.values(propertyPage?.children).flat(),
      { ...homePage, children: propertyPageLocationNav },
    ].filter((child) => child.template !== "property_settings");

    return propertyPage?.children
      ? propertyNav.map((mainNav) => {
          let subNav = Object.values(mainNav?.children || {}).flat();
          mainNav.template === "about" &&
            (subNav = [overviewSubMenuItem, ...subNav]);

          mainNav.template === "home" &&
            (subNav = [aboutTheBrandSubMenuItem, ...subNav]);

          return {
            mainNav: {
              ...pagesContent.content[1],
              ...mainNav,
              title: mainNav.linktitle,
            },
            subNav,
          };
        })
      : [];
  };

  // Brand nav items handler
  const brandNavItems = (array) => {
    // TODO: consider using content from CMS for these properties
    const locationsSubMenuItems = [
      {
        linktitle: "Our Locations",
        title: "Overview",
        path: "/our-locations",
        slug: "our-locations",
      },
    ];

    return array?.map((element) => ({
      mainNav: element,
      subNav:
        element.h2 === "our-locations"
          ? [
              ...locationsSubMenuItems,
              ...childrenByPath("/locations").map((element) => ({
                ...element,
                linktitle: (
                  <>
                    {/* Main nav uses TITLE, not linktitle. Title is a custom attribute is a custom field in the CMS, not the same as the page content object's "title"*/}
                    {/* see example: https://lepetitpali.ideaworkwidgets.com/admin/content/23 */}
                    {element.title}
                    {element.checkbox1 && (
                      <span className={cl("coming-soon")}>(Coming Soon)</span>
                    )}
                  </>
                ),
              })),
            ]
          : [],
    }));
  };

  // Scroll handler for updating scrolling state
  useEffect(() => {
    function handleScroll() {
      setUserScrolling(window.scrollY >= 150);
    }

    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [setUserScrolling]);

  // Determine the menu list depending on the context
  const determineMenuList = isHotel
    ? propertyNavItems()
    : brandNavItems(header?.fieldgroup1);

  // Header JSX
  return (
    <header className={cl(["_", userScrolling && "sticky"])}>
      <div className={cl("hamburger-wrapper")}>
        <Hamburger />
      </div>
      {showLogo && (
        <AnimatePresence>
          <motion.div
            key="logo"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Link className={cl("logo")} path={logoLink}>
              <Logo src={logo} className={cl("logo_inner")} />
            </Link>
          </motion.div>
        </AnimatePresence>
      )}
      <div className={cl("cta-wrapper")}>
        <Button2
          text={header?.h3}
          onClick={() => setBookNowModal(!bookNowModal)}
        />
      </div>
      <NavBookingWidget />
      <Menu navItems={determineMenuList} isHotel />
    </header>
  );
}

export default Header;
