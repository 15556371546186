import { Link as RRLink } from "react-router-dom";
import useAppState from "../providers/AppProvider";
import styles from "../../styles/widgets/_link.scss";
import { _classes } from "../utilities/helpers";

const cl = _classes(styles);

Link.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClick: PropTypes.func,
  path: PropTypes.string,
  search: PropTypes.string,
  hash: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.string,
  ariaLabel: PropTypes.string,
  target: PropTypes.string,
  tabIndex: PropTypes.string,
  ariaSelected: PropTypes.func,
  brandLink: PropTypes.bool,
};

Link.defaultProps = {
  target: "_blank",
  path: "",
  search: "",
  hash: "",
  tabIndex: "0",
};

function Link({
  children,
  id,
  path,
  search,
  hash,
  title,
  className,
  onClick,
  ariaLabel,
  target,
  tabIndex,
  ariaSelected,
  brandLink,
}) {
  const { byId, byPath } = useAppState();

  const getContent = (id, path) => {
    let content = {};
    if (typeof id === "number") {
      content = byId(id);
    } else if (path) {
      content = byPath(path);
    }

    return content.id ? content : null;
  };

  const getDomain = (url) => url.replace(/https?:\/\//, "").split("/")[0];

  const isEmail = (url) => url.search(/mailto:/) !== -1;

  const isFile = (url) => url.search(/\.pdf/) !== -1;

  const isPhone = (url) => url.search(/tel:/) !== -1;

  const isExternal = (path) => {
    if (!path || isEmail(path) || isFile(path) || isPhone(path)) {
      return true;
    }
    const { href } = window.location;
    const sameDomain = getDomain(href) === getDomain(path);
    return path.search(/https?:\/\//) !== -1 && !sameDomain;
  };

  const content = getContent(id, path);

  // should we even return anything here?
  if (!content && !path && !onClick) {
    return (
      <span
        aria-label={ariaLabel}
        className={cl([brandLink && "link", className])}
        aria-selected={ariaSelected}
        tabIndex={tabIndex}
      >
        {title || children}
      </span>
    );
  }

  if (!content && !path && onClick) {
    return (
      <button
        aria-label={ariaLabel}
        className={cl([brandLink && "link", className])}
        onClick={onClick}
        aria-selected={ariaSelected}
        tabIndex={tabIndex}
      >
        {title || children}
      </button>
    );
  }

  // handle routes with associated content or param routes created by the router
  if (content || !isExternal(path)) {
    return (
      <RRLink
        aria-label={ariaLabel}
        aria-selected={ariaSelected}
        tabIndex={tabIndex}
        className={`${cl([brandLink && "link", className])} ${className}`}
        onClick={onClick}
        to={{
          pathname: path,
          search,
          hash,
        }}
      >
        {title || children || path}
      </RRLink>
    );
  }

  // external links,  mailto, tel, etc
  return (
    <a
      className={`${cl([brandLink && "link"])} ${className}`}
      onClick={onClick}
      aria-label={ariaLabel}
      href={`${path}${hash}${search}`}
      target={target}
      tabIndex={tabIndex}
      aria-selected={ariaSelected}
    >
      {title || children || path}
    </a>
  );
}

export default Link;
