import ErrorMessage from "../ErrorMessage/ErrorMessage";
import Label from "../Label/Label";
import { Controller } from "react-hook-form/dist/index.ie11";
import Select from "react-select";
import { useFormContext } from "../FormProvider";
import { v4 as uuidv4 } from "uuid";
import styles from "./SelectInput.scss";
import { _classes } from "../../utilities/helpers";

const cl = _classes(styles);

const UUID = uuidv4();

SelectInput.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  middleware: PropTypes.func,
  label: PropTypes.string,
  rules: PropTypes.object,
  options: PropTypes.array,
  className: PropTypes.string,
  noOptionsText: PropTypes.func,
  selectClassName: PropTypes.string,
  errorClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  defaultValue: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.any,
  }),
  isDisabled: PropTypes.bool,
  isHidden: PropTypes.bool,
};

SelectInput.defaultProps = {
  backgroundColor: "",
  className: "",
  selectClassName: "",
  errorClassName: "",
  labelClassName: "",
  placeholder: "",
  isDisabled: false,
  isHidden: false,
  noOptionsText: () => "No options",
};

export default function SelectInput({
  name,
  placeholder,
  middleware,
  label,
  options = [],
  rules,
  className,
  noOptionsText,
  selectClassName,
  errorClassName,
  labelClassName,
  defaultValue,
  isDisabled,
  isHidden,
}) {
  const { control, errors } = useFormContext();
  const id = `${name}__${UUID}`;
  return (
    <Controller
      control={control}
      defaultValue={defaultValue?.value}
      name={name}
      rules={rules}
      render={({ onChange, value }) => {
        return (
          <div
            // Note: inlining display none here to prevent FOUCs
            style={{ display: isHidden ? "none" : "" }}
            className={cl(["root", className])}
          >
            <Label
              value={label}
              htmlFor={id}
              className={cl(["label", labelClassName])}
            />

            <div className={cl("control")}>
              <Select
                noOptionsMessage={() => noOptionsText()}
                inputProps={{ id }}
                aria-labelledby="aria-label"
                name={name}
                label={label}
                placeholder={placeholder}
                value={options.find((rec) => rec.value === value)}
                onChange={(selected) => {
                  onChange(selected ? selected.value : null);
                  middleware && middleware(selected ? selected.value : null);
                }}
                options={options}
                classNamePrefix={"select"}
                className={cl(["select", selectClassName])}
                isSearchable={false}
                disabled={isDisabled}
              />
            </div>
            <div className={cl("error")}>
              <ErrorMessage error={errors[name]} className={errorClassName} />
            </div>
          </div>
        );
      }}
    />
  );
}
