import React from "react";
import { _classes } from "../../utilities/helpers";
import styles from "../../../styles/partials/forms/NewsletterForm.scss";
import { FormProvider, StandardInput } from "../../form";
import Reveal from "../../widgets/Reveal";
import { playIcon } from "../../widgets/SVGs/playIcon";

const cl = _classes(styles);
export default function NewsletterForm() {
  return (
    <div className={cl("_")}>
      <FormProvider onSubmit={"newsletter"}>
        <div className={cl("row")}>
          <Reveal className={cl("reveal_wrapper")}>
            <StandardInput
              name="email"
              placeholder="Sign up for our newsletter"
              rules={{ required: "Please enter a valid email." }}
              className={cl("input")}
              inputClassName={cl("input_field")}
              labelVisible={false}
            />
          </Reveal>
          <button className={cl("submit")}>{playIcon}</button>
        </div>
      </FormProvider>
    </div>
  );
}
