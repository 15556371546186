import { InstagramIcon } from "../widgets/SVGs/InstagramIcon";
import styles from "../../styles/partials/FooterGallery.scss";
import { parse, _classes } from "../utilities/helpers";
import FooterGalleryItem from "../components/FooterGalleryItem";
import { Link } from "../widgets";
const cl = _classes(styles);
FooterGallery.propTypes = {
  galleryContent: PropTypes.array,
};
export default function FooterGallery({
  galleryContent,
  galleryMediaLink,
  galleryMediaTitle,
}) {
  return (
    <div className={cl("container")}>
      <Link path={galleryMediaLink}>
        <div className={cl("title")}>
          <span>{galleryMediaTitle}</span>
          <div className={cl("title_logo")}>
            <InstagramIcon height={"22"} width={"22"} />
          </div>
        </div>
      </Link>
      <div className={cl("gallery_body")}>
        {galleryContent &&
          galleryContent.map((item) => {
            return (
              <FooterGalleryItem
                key={item.id}
                src={item.image1}
                link={parse(item.buttonlink1).link}
                videoLink={item.video_url}
              />
            );
          })}
      </div>
    </div>
  );
}
