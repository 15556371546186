import ErrorMessage from "./ErrorMessage/ErrorMessage";
import Label from "./Label/Label";
import { useFormContext } from "./FormProvider";
import { v4 as uuidv4 } from "uuid";

const UUID = uuidv4();

RadioInput.propTypes = {
  label: PropTypes.string,
  rules: PropTypes.object,
  name: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      defaultValue: PropTypes.string,
      checked: PropTypes.bool,
      className: PropTypes.string,
    })
  ),
};

RadioInput.defaultProps = {
  label: "Label",
  className: "",
  options: [],
};

export default function RadioInput({ label, options, className, rules, name }) {
  const { errors, register } = useFormContext();
  const id = `${name}__${UUID}`;

  return (
    <div className={"field field-radio-group " + className}>
      <span>{label}</span>
      <div className={"radio-group"}>
        {options.map(({ label, value, className }) => (
          <div className={"field-radio " + className} key={label}>
            <div className={"control"}>
              <input
                id={id}
                name={name}
                ref={register(rules)}
                type="radio"
                value={value}
                className={"radio"}
              />
              <div className={"radio-toggle"}>
                <div />
              </div>
            </div>
            <Label value={label} htmlFor={id} />
          </div>
        ))}
      </div>
      <ErrorMessage error={errors[name]} />
    </div>
  );
}
