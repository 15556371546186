import { _classes } from "../../utilities/helpers";
import styles from "../../../styles/components/header-components/Logo.scss";
const cl = _classes(styles);
Logo.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
};
export default function Logo({ className, src }) {
  return (
    <div className={`${cl("_")} ${className}`}>
      {src && <img src={src} alt="logo" />}
    </div>
  );
}
