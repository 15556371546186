import React from "react";
import styles from "../../../styles/partials/header-components/Menu.scss";
import useAppState from "../../providers/AppProvider.js";
import { _classes } from "../../utilities/helpers";
import { motion } from "../../utilities/FramerMotion";
import Modal from "../../widgets/Modal";
import Nav from "../header-components/Nav";
import { Close } from "../../widgets/SVGs/Close";

Menu.propTypes = {
  navItems: PropTypes.array,
  isHotel: PropTypes.bool,
};
export default function Menu({ navItems, isHotel }) {
  const { menuOpen, setMenuOpen } = useAppState();
  const cl = _classes(styles);

  const variants = {
    animate: {
      x: !menuOpen ? -600 : 0,
      transition: { duration: 0.3, times: [0, 0.2, 1] },
    },
    initial: { x: -600 },
  };

  const toggleMenu =() => setMenuOpen((prevValue) => !prevValue);

  return (
    <Modal visible={menuOpen} close={() => toggleMenu()}>
      <motion.div className={cl("root")} {...variants}>
        <div
          className={cl("close")}
          onClick={() => toggleMenu()}
          tabIndex="0"
          onKeyDown={(e) =>
            e.key === "Enter" || (e.key === " " && toggleMenu())
          }
        >
          {Close()}
        </div>
        <div className={cl("wrapper")}>
          <Nav selections={navItems} toggleMenu={toggleMenu} />
        </div>
      </motion.div>
    </Modal>
  );
}
