// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._modal__modal___9bHMI{position:fixed;width:100%;height:100%;top:0;left:0;bottom:0px;right:0px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;pointer-events:none;z-index:-1;background:rgba(0,0,0,.75)}._modal__modal___9bHMI._modal__open___aBH9R{pointer-events:all;z-index:9999}._modal__modal__container___knjII{display:block;position:relative;height:100%;width:100%}[class*=\"slick-arrow slick-prev\"]{-webkit-transform:translate(0, -50%) rotate(180deg) !important;transform:translate(0, -50%) rotate(180deg) !important}", "",{"version":3,"sources":["webpack://./src/styles/widgets/_modal.scss"],"names":[],"mappings":"AAAA,uBACE,cAAA,CACA,UAAA,CACA,WAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,SAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,uBAAA,CAAA,oBAAA,CAAA,sBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,mBAAA,CACA,UAAA,CACA,0BAAA,CAEA,4CACE,kBAAA,CACA,YAAA,CAIJ,kCACE,aAAA,CACA,iBAAA,CACA,WAAA,CACA,UAAA,CAEF,kCACE,8DAAA,CAAA,sDAAA","sourcesContent":[".modal {\n  position: fixed;\n  width: 100%;\n  height: 100%;\n  top: 0;\n  left: 0;\n  bottom: 0px;\n  right: 0px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  pointer-events: none;\n  z-index: -1;\n  background: rgba(0, 0, 0, 0.75);\n\n  &.open {\n    pointer-events: all;\n    z-index: 9999;\n  }\n}\n\n.modal__container {\n  display: block;\n  position: relative;\n  height: 100%;\n  width: 100%;\n}\n[class*=\"slick-arrow slick-prev\"] {\n  transform: translate(0, -50%) rotate(180deg) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "_modal__modal___9bHMI",
	"open": "_modal__open___aBH9R",
	"modal__container": "_modal__modal__container___knjII"
};
export default ___CSS_LOADER_EXPORT___;
