import styles from "../../styles/components/CursiveText.scss";
import { _classes } from "../utilities/helpers";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

const cl = _classes(styles);

CursiveText.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  fontSize: PropTypes.oneOf(["sm", "lg"]),
  fontColor: PropTypes.oneOf(["green", "white"]),
};

function CursiveText({
  text,
  fontSize,
  fontColor,
  className,
  animateIn,
  mediaLink,
}) {
  const defaultText =
    "These are the best days of our lives, what a celebratory time.";
  const textToAnimate = text ? text : defaultText;
  // animation
  const letters = Array.from(textToAnimate);
  const ctrls = useAnimation();
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      ctrls.start("visible");
    }
    if (!inView) {
      ctrls.start("hidden");
    }
  }, [ctrls, inView]);

  const wordAnimation = {
    hidden: {},
    visible: {},
  };

  const characterAnimation = {
    hidden: {
      opacity: 0,
      y: `0.25em`,
    },
    visible: {
      opacity: 1,
      y: `0em`,
      transition: {
        duration: 1,
        ease: [0.2, 0.65, 0.3, 0.9],
      },
    },
  };

  const routeToMediaLink = () => {
    if (mediaLink) window.open(mediaLink);
    return;
  };
  return (
    <div
      className={`cursive ${cl([
        "_",
        "word",
        fontSize,
        fontColor,
      ])} ${className}`}
    >
      {animateIn ? (
        <motion.div
          tabIndex="0"
          onClick={routeToMediaLink}
          ref={ref}
          aria-hidden="true"
          initial="hidden"
          animate={ctrls}
          variants={wordAnimation}
          transition={{
            delayChildren: 0.25,
            staggerChildren: 0.05,
          }}
        >
          {letters.map((letter, index) => {
            return (
              <motion.span
                className={cl("character")}
                aria-hidden="true"
                key={index}
                variants={characterAnimation}
              >
                {letter}
              </motion.span>
            );
          })}
        </motion.div>
      ) : (
        <div
          className={`${cl(["_", fontSize, fontColor])} ${className}`}
          tabIndex="0"
        >
          {textToAnimate}
        </div>
      )}
    </div>
  );
}

export default CursiveText;
