import * as typedefs from "../../../typedefs";
import { useRouteMatch } from "react-router-dom";
import useAppState from "./../providers/AppProvider";

export const isDevelopment = process.env.NODE_ENV !== "production";

export const parse = (json, fallback = false) => {
  try {
    if (json === null || json === "" || json === undefined) {
      return fallback;
    }

    return JSON.parse(json) || fallback;
  } catch (e) {
    console.error(e);
    return fallback;
  }
};

// Creates a range (array) of numbers.
export const range = (integer, start = 0) =>
  [...Array(parseInt(integer)).keys()].map((i) => i + parseInt(start));

//form select options helper
export const selectOptionHelper = (arr) => {
  return arr.map((ele) => {
    return { value: ele, label: ele };
  });
};

// Capitalize a string.
export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

// abbreviate class name with a prefix
export const _classes = (styles) => (name) => {
  if (typeof name === "string") {
    return styles[name] || name || "";
  }

  if (Array.isArray(name)) {
    return name.map((n) => styles[n] || n || "").join(" ");
  }

  return "";
};

export const stripTrailingSlash = (str) => {
  return str?.endsWith("/") ? str.slice(0, -1) : str;
};
// Limit how often a function fires.
// Great for event listeners like onResize
// delay in ms
export const debounce = (delay, fn) => {
  let time = Date.now();

  return function debounced(e) {
    if (time + delay - Date.now() < 0) {
      fn(e);
      time = Date.now();
    }
  };
};

//sleep
export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

//for accessible users
export const forceFocus = (id, event, key) => {
  if (event.key === key) document.getElementById(id).focus();
};

export const errorHandlingHelper = (object, key, button) => {
  let hasContent;

  if (button) {
    hasContent =
      object[key] !== "" &&
      object[key] !== null &&
      JSON.parse(object[key]).text !== "" &&
      JSON.parse(object[key]).link !== "";
  } else hasContent = object[key] !== "" && object[key] !== null;

  if (button && hasContent) {
    const parsedObject = JSON.parse(object[key]);
    return {
      text: parsedObject.text,
      link: parsedObject.link,
    };
  } else if (hasContent) return object[key];
  else return "";
};

//Created border dashes
export const generateDashes = (length) => {
  //Only setting two breakpoints and will just hide the excess dashes

  let dash = [];
  for (let i = 0; i < length; i++) {
    dash.push(<div className={cl("border__dash")} />);
  }
  return dash;
};
export const returnPropertyBySlug = (items, pathname) =>
  items.filter((page) => {
    return pathname.includes(page.slug);
  });

/**

  /**
   * 
   */
export class PagesContent {
  // add a cache to store instances of this class
  static instances = {};

  constructor(content, doNotChain = false, whereKey = "id") {
    // The key to use for the where() method
    /**
     * @property {string} whereKey - The key to use for the where() method
     * @example pagesContent.whereKey = "parentid" // now the where() method will use the parentid key to find content
     */
    this.whereKey = whereKey;

    // if an instance of this class has been created with the same arguments, return it
    if (PagesContent.instances[whereKey]) {
      // log
      // console.log(
      //   "returning cached instance of PagesContent",
      //   whereKey,
      //   this.whereKey,
      //   PagesContent.instances[whereKey]
      // );
      PagesContent.instances[whereKey] = this;
    } else {
      // otherwise, cache this instance and return it
      PagesContent.instances[whereKey] = this;
      // log
      // console.log(
      //   "creating new instance of PagesContent",
      //   whereKey,
      //   this.whereKey,
      //   PagesContent.instances[whereKey]
      // );
    }

    this.doNotChain = doNotChain;
    this.content =
      typeof content === "object" ? JSON.parse(JSON.stringify(content)) : {}; // deep clone
    this._content =
      typeof content === "object" ? JSON.parse(JSON.stringify(content)) : {}; // deep clone
    // the content as a Map, where the key is always a string if it's a number for .get(); (ex: "32" instead of 32) and the value is the page object (ex: {id: 26, parentid: 3, pagetitle: "About Us", …})
    this.asMap = new Map(
      Object.keys(this._content).map((k) => [`${[k]}`, this._content[k]])
    );
    this.asArray = [...this.asMap.values()];

    /**
     * @description - find content by specific key and value.
     * @param key  - the key to search for
     * @param value - the value to search for
     * @returns  Array<Object> - an array of objects that match the key/value pair
     * @example pagesContent.getBy("parentid", 26) // returns an array of objects that have a parentid of 26
     */
    this.getBy = function (key, value) {
      return this.asArray.filter((page) => page[key] === value);
    };
    return this;
  }

  // // remove falsey values to reduce bloat.
  static compactObject = (data) => {
    if (Array.isArray(data)) {
      return data;
    }
    return Object.keys(data).reduce(
      (acc, key) => {
        const value = data[key];
        if (Boolean(value))
          acc[key] =
            typeof value === "object"
              ? PagesContent.compactObject(value)
              : value;
        return acc;
      },
      Array.isArray(data) ? [] : {}
    );
  };

  static constrainObjectToKeys = (obj, keys = []) => {
    let cleanedObj = {};
    for (let itemKey in obj) {
      typeof Object.values(obj[itemKey])[0] == "object" &&
      Object.keys(obj[itemKey])?.length > 0
        ? (cleanedObj[itemKey] = constrainObjectToKeys(obj[itemKey], keys))
        : (cleanedObj[itemKey] = {});

      keys.forEach(
        (k, v, i) =>
          typeof obj[itemKey][k] !== "undefined" &&
          (cleanedObj[itemKey][k] = obj[itemKey][k])
      );
    }
    return cleanedObj;
  };

  get data() {
    return this._content;
  }

  get _where() {
    const withNewWhereKey = this.where(this.whereKey);
    window.pagesContent = withNewWhereKey;
    return this;
  }

  set _whereKey(key) {
    this.whereKey = key;
    return this._where;
  }

  get reset() {
    const resetThis = new this.constructor(
      this.content,
      this.doNotChain,
      this.whereKey
    );
    // window.pagesContent = resetThis;
    return resetThis;
  }

  // ************************************************ //
  // ************************************************ //

  fieldsLike = (regex) => {
    let matches = {};
    for (let page of this.reset.asArray) {
      for (let value in page) {
        if (
          (typeof page[value] === "string" && page[value]?.match(regex)) ||
          (typeof regex === "string" &&
            typeof page[value] === "string" &&
            page[value]?.includes(regex))
        ) {
          if (!matches[page.id]) {
            matches[page.id] = [[], page];
          }
          matches[page.id][0].push({ key: value, value: page[value] }); // = [[...matches[page.id][0], value],page];
        }
      }
    }

    this._content = matches;
    return new this.constructor(this._content, this.doNotChain, this.whereKey);
  };

  // returns single Object if one element exists, or Array if more than one match
  is = (key) => {
    let data = {};

    // check if is Map or object
    if (this._content instanceof Map) {
      // if Map, then get the value of the key
      data = this._content.get(`${key}`);
    } else if (
      typeof this._content === "object" &&
      this._content.hasOwnProperty(key)
    ) {
      // if object, then get the value of the key
      data = this._content[key];
    } else if (Array.isArray(this._content) && this._content.length === 1) {
      data = this._content[0];
    } else {
      data = this._content || this.content;
    }
    this._content = Array.isArray(data) && data.length === 1 ? data?.[0] : data;
    // if chaining is disabled, then return the data, otherwise return a new instance of the class
    return this.doNotChain
      ? this._content
      : new this.constructor(this._content, this.doNotChain, this.whereKey);
  };

  // always return Array
  has(key) {
    this.reset;
    this._content = [].concat(
      this._content?.get?.(key) || this._content?.[key] || this._content
    );
    return this.doNotChain
      ? this._content
      : new this.constructor(this._content, this.doNotChain, this.whereKey);
  }

  where = (key, c = null) => {
    this.whereKey = key || "id";

    if (!c) c = this.reset.asArray;

    // Create a Map object to store the content
    const _ = new Map();
    // Loop through the content
    for (let i = 0; i < c.length; i++) {
      // Get the iteration of the content object by index
      const p = c[i];
      // Get the value of the key that we are searching for
      const k = `${p[key]}`;
      // Set or update the Map with the key/value pair.;
      // If the key already exists, add the page object to the array of page objects for the search key.
      // ex: if the key "26" already exists --> Map(1) {"26" => Array(2)}
      _.set(k, _.has(k) ? [..._.get(k), p] : [p]);
    }

    // Set the content to be the Map object
    this._content = Object.fromEntries(_);
    this.asMap = _;
    // this.asArray = [..._.values()];
    // window.pagesContent.asMap = _; // = new this.constructor(this._content, this.doNotChain, this.whereKey);
    // window.pagesContent._content = Object.fromEntries(_); // = new this.constructor(this._content, this.doNotChain, this.whereKey);
    return this;
  };

  nestedData = ({
    data = !data?.length || [],
    compactData = true,
    childrenAs = "array",
    whereKey = "path",
  }) => {
    // create a flat array of objects
    const flat = Object.keys(data).map((key) => ({
      ...data[key],
      children: childrenAs === "array" ? [] : [],
      // children: data[key]?.parentid == 0 && childrenAs === "object" ? {} : []
    }));

    // Create the root for top-level node(s)
    const root = {};

    // place nodes without a parent in the root (or top-leve, ex: 1 && 2 in our case...l)
    flat.forEach((node, index) => {
      const { children } = node;
      // No parentId means top level
      if (node.parentid == 0) return (root[node.id] = node);
      // if (node.parentid == 1) root[node.id] = node
      node = compactData ? PagesContent.compactObject(node) : node;
      if (flat[node.parentid] && node.parentid == node.id) {
        childrenAs === "array"
          ? flat[parentIndex]?.children.push(node)
          : (flat[parentIndex].children[node[whereKey]] = [
              {
                ...node,
                children,
              },
            ]);
      }

      // console.log("flat:::: ", flat);

      // Insert node as child of parent in flat array. Updates the reference in flat
      const parentIndex = flat.findIndex((el) => el.id == node.parentid);
      if (flat[parentIndex] && !flat[parentIndex]?.children) {
        return (flat[parentIndex].children =
          childrenAs === "array"
            ? [node]
            : [
                {
                  [node[whereKey]]: { ...node, children },
                },
              ]);
      }

      // console.log("whereKey:::: ", whereKey);
      this.whereKey;
      // console.log("this.whereKey:::: ", this.whereKey);

      flat[parentIndex] &&
        (childrenAs === "array"
          ? flat[parentIndex].children.push(node)
          : flat?.[parentIndex]?.children?.[node?.[whereKey]]
          ? flat?.[parentIndex]?.children?.[node?.[whereKey]].push(node)
          : !flat?.[parentIndex]?.children?.[node?.[whereKey]]
          ? // no items in the child yet
            (flat[parentIndex].children[node?.[whereKey]] = [node])
          : (flat[parentIndex].children[node?.[whereKey]] = node));

      // (flat[parentIndex].children[node?.[whereKey]] = toObject(
      //     [...flat?.[parentIndex]?.children?.[node?.[whereKey]], node] // if the key exists, add the new node to the array
      //     // : [node]) // if the key doesn't exist, create a new array with the new node
      //     // [node[whereKey]]: { ...node, children }
      //   )));
      // {
      //   ...node,

      // ?.push({
      //   ...node,
      //   children,
      // })
    });

    return root;
  };
}

// window.pagesContent = new PagesContent(CONTENT.data)

// Example #1
// ----------
/**
 * @example pagesContent.where("title").is("Homepage Sections")
 */

// Example #2
// WARNING: Do NOT nest like: pagesContent.where(pagesContent.where(...)), unless calling pagesContent.reset within the inner functions
// ----------
/**
 * @example
 *  const homepageId = pagesContent.where("title").is("Homepage Sections").data.id;
 *  pagesContent.where("parentid").is(homepageId).data;
 */

// Example #3 -- Don't chain the class. Auto-return the data
// ---------------------------------------------------------
/**
 * @example
 * pagesContent = new PagesContent(window.pagesContent.content, true);
 * pagesContent.where("title").is("Homepage Sections");
 * @returns {Object} - the page object that matches the search criteria
 * @returns {Array} - an array of page objects that match the search criteria
 * @returns @example {id: 26, title: "Homepage Sections", parentid: 0, template: "homepage-sections", ...}
 */
// TODO::: Use TypeScript to enforce allowable keys in `.where(KEY_NAME)` and the data type allowed for `.is(NUMBER|STRING|...)`

// Example #4 -- Deeply nest data
// TODO::: use same `.where()` method on children when nesting, ex: "path", instead of `{"locations": children:[{},{},{}]}`, `{"locations": children:{"/locations/somewhere": {...}, "/locations/anotherplace":{...}}}
// note: `1` is the "base/home" content in which everything else resides
// const nestedContent = pagesContent.nestedData(pagesContent.content)[1].children
// pagesContent.where("path", nestedContent)

// pagesContent._whereKey = "h1"
// console.log(pagesContent.data)
// console.log(pagesContent.nestedData(pagesContent.content)[1].children)

// pagesContent._whereKey = "path"
// console.log(pagesContent.data)
// console.log(pagesContent.nestedData(pagesContent.content)[1].children)

// pagesContent._whereKey = "slug"
// console.log(pagesContent.data)

/******/

/** @type {typedefs.useCurrentBasePageContent}*/
export const useCurrentBasePageContent = ({
  pathBase = "locations",
  pathSlug = "property",
  whereKey = "path", // ie: any key from the CMS Page object. ex: "path", "slug", "h1", "title", etc.
  childrenAs = "object", // "array" or "object"
} = {}) => {
  const { pagesContent } = useAppState();
  // important to reset whereKey to path each time this hook is called; Make immutable.
  pagesContent._whereKey = whereKey;

  let match = useRouteMatch(`/${pathBase}/:${pathSlug}`);
  match = {
    ...match,
    url: stripTrailingSlash(match?.url),
  };

  // ie: the '/locations' page content, but recursively houses all individual property pages under children,
  // with whatever key is specified in `whereKey` and shaped as `childrenAs` (array or object[whereKey]).
  // @example: {"/locations": {"/locations/somewhere": {...}, "/locations/anotherplace":{...}}
  /**
   * @example
   *  {
   *    "id": 6,
   *    "parentid": 1,
   *    "title": "Properties",
   *    "linktitle": "Locations",
   *    "path": "/locations",
   *    "child_delete": true,
   *    "children": [
   *      "/locations/at-ocean-ave-carmel-by-the-sea: Array(1), /locations/at-8th-ave-carmel-by-the-sea: Array(1), /locations/brentwood: Array(1), /locations/laguna-beach: Array(1)"
   *    ]
   *  }
   */
  let allProperties = pagesContent.nestedData({
    data: pagesContent.content,
    childrenAs,
    whereKey,
    // "1" = Home in the CMS. All content exists under Home.
  });
  // console.log("allProperties1:::", allProperties);

  // STOPPED_HERE:::::
  allProperties = { ...allProperties["1"]?.children };

  // console.log("allProperties2:::", allProperties);

  allProperties = allProperties[`/${pathBase}`]?.[0];

  // console.log("allProperties3", allProperties);

  if (!allProperties) {
    return {
      match,
      basePageContent: {
        ...propertyContent,
        // THIS:::: STOPPED_HERE::: FKD IT UP
        children:
          childrenAs === "object"
            ? { ...propertyContent?.children }
            : Object.values(propertyContent?.children || []),
      },
      topLevelContent: allProperties,
    };
  }

  // allProperties = allProperties[whereKey];

  const propertyContent = { ...allProperties?.children }?.[match?.url]?.[0];
  // const propertyContent = ({ ...allProperties?.children })?.[match?.url]

  // console.log("propertyContent:::::: ", propertyContent);

  // // Note: Might also be hidden in CMS "hideInNav" option...
  // delete propertyContent?.children?.[`${match?.url}/property-settings`];

  // console.log('propertyContent.children:::: ', propertyContent?.children)

  return {
    match,
    basePageContent: {
      ...propertyContent,
      // children: propertyContent?.children || [],
      children: Object.entries(propertyContent?.children || []).map(
        (x) => x[1][0]
      ),
      // children: Object.entries(propertyContent?.children || []).map(x => x[1][0])
      // children: Object.values(propertyContent?.children || [])
      // children: childrenAs === "object" ? { ...propertyContent.children } : (propertyContent?.children || [])
    },
    topLevelContent: allProperties,
  };
};

export const toObject = (arr = [], key = "") => {
  return arr.reduce(function (acc, cur, i) {
    acc[cur?.[key] || i] = cur;
    return acc;
  }, {});
};
