export const ByPaliSocietyLogo = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 161.3 14.6"
      height="15px"
      width="150px"
    >
      <path
        className="st0"
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeWidth="0.5376"
        strokeMiterlimit="2.1505"
        d="M25.1,10.1H17V9.6h1.4c0.9,0,1.7-0.3,1.7-1.4V2.5c0-1.2-0.8-1.4-1.7-1.4H17V0.6h9.7c1.5,0,3.2,0.6,3.2,2.5
 c0,2.3-2.1,2.5-4.1,2.5h-3.8v2.5c0,1.2,0.8,1.4,1.7,1.4h1.4C25.1,9.6,25.1,10.1,25.1,10.1z M25.2,5.2c1.6,0,2.7-0.6,2.7-2.1
 c0-1.7-1.2-2.1-2.4-2.1h-3.4v4.2H25.2z M43.8,10.1h-7.3V9.6h1c0.8,0,1.1-0.4,1.1-0.9c0-0.3-0.1-0.6-0.3-0.9l-0.7-1.2h-4.1L33,7.9
 c-0.1,0.3-0.2,0.5-0.2,0.8c0,0.6,0.8,0.9,1.5,0.9H35V10h-6V9.6h0.9c1.4,0,2.2-0.9,2.6-1.9l3.4-7.4h0.4l4.2,7.4
 c0.8,1.3,1.3,1.9,2.7,1.9h0.5v0.5L43.8,10.1z M35.4,2.6l-1.7,3.5h3.7C37.4,6.2,35.4,2.6,35.4,2.6z M56.4,10.1H44.9V9.6h1.4
 c0.9,0,1.7-0.3,1.7-1.4V2.5c0-1.2-0.8-1.4-1.7-1.4h-1.4V0.6H53v0.4h-1.6c-0.9,0-1.7,0.3-1.7,1.4v7.2h3.1c2,0,2.8-1.6,3.1-3h0.4
 C56.4,6.7,56.4,10.1,56.4,10.1z M65.8,10.1H58V9.6h1.2c0.9,0,1.7-0.3,1.7-1.4V2.5c0-1.2-0.8-1.4-1.7-1.4H58V0.6h7.7v0.4h-1.2
 c-0.9,0-1.7,0.3-1.7,1.4v5.7c0,1.2,0.8,1.4,1.7,1.4h1.2V10.1z M130.8,9.9h-12.4V9.5h1.2c0.9,0,1.7-0.3,1.7-1.4V2.4
 c0-1.2-0.8-1.4-1.7-1.4h-1.2V0.6h12v3.2H130c-0.3-1.4-1.1-2.8-3.8-2.8h-3.2v3.4h2.7c0.9,0,1.4-0.2,1.4-1.2v-1h0.4v5h-0.4V6
 c0-0.8-0.5-1.1-1.4-1.1h-2.7v4.7h4.2c1.8,0,2.8-1.6,3.1-2.7h0.4C130.8,6.8,130.8,9.9,130.8,9.9z M142.4,9.9h-7.9V9.5h1.4
 c0.9,0,1.7-0.3,1.7-1.4V1h-0.9c-2.3,0-3,1.8-3.2,3H133V0.5h10.8v3.4h-0.4c-0.2-1.1-0.9-3-3.2-3h-0.9v7.2c0,1.2,0.8,1.4,1.7,1.4h1.4
 L142.4,9.9L142.4,9.9z M154.2,0.5V1h2.3c0.7,0,1,0.1,1,0.4c0,0.2-0.3,0.4-0.4,0.5c-0.1,0-0.1,0.1-0.1,0.1l-3.5,3L150,1.6
 c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1,0-0.3,0.6-0.3h1.5V0.5h-6.8V1h0.8c0.8,0,1.2,0.2,2.2,1l3.9,3.7v2.8c0,0.6-0.3,0.9-1.1,0.9h-1.3v0.5
 h6.8V9.4h-1.4c-0.9,0-1.1-0.2-1.1-0.9V5.5l4.2-3.5c0.7-0.6,1.4-0.9,2-0.9h1V0.5H154.2L154.2,0.5z M86.4,10.2c-4.3,0-6.3-2.4-6.3-4.9
 c0-2.8,2.6-4.9,6.3-4.9c3.8,0,6.3,2.2,6.3,4.9C92.8,7.7,90.7,10.2,86.4,10.2 M90.6,5.3c0-3.8-2.4-4.6-4.1-4.6c-1.9,0-4.2,1-4.2,4.6
 c0,2.6,1.3,4.5,4.2,4.5C89.3,9.8,90.6,7.8,90.6,5.3 M116.2,9.9h-7.7V9.5h1.2c0.9,0,1.7-0.3,1.7-1.4V2.4c0-1.2-0.8-1.4-1.7-1.4h-1.2
 V0.5h7.7V1H115c-0.9,0-1.7,0.3-1.7,1.4v5.7c0,1.2,0.8,1.4,1.7,1.4h1.2C116.2,9.5,116.2,9.9,116.2,9.9z M105.8,5.5
 c-0.3,0-0.4,0.5-0.4,1c0,2-0.7,3.3-4,3.3c-2.4,0-4.7-1.3-4.7-4.2c0-1.4,0.5-4.7,4.7-4.7c3.1,0,3.8,1.8,3.9,2.2V3h0.5V0.6h-0.5
 l-0.1,0.1C105.2,0.8,105,1,104.6,1c-0.4,0-0.6-0.1-1-0.3h-0.1c-0.5-0.2-1.1-0.4-2.4-0.4c-2,0-3.8,0.5-5,1.6
 c-1.1,0.9-1.7,2.1-1.7,3.4c0,2.5,2,4.9,6.7,4.9c3.5,0,5.3-1.7,5.3-3.3C106.5,6.1,106.1,5.5,105.8,5.5 M74.9,4.6l-3.3-0.8
 c-0.5-0.1-1.5-0.5-1.5-1.4c0-1.3,1.5-1.6,2.8-1.6c2.3,0,3.8,0.8,4.4,2.2l0.1,0.1h0.5V0.6h-0.5v0.1c-0.1,0.3-0.2,0.5-0.6,0.5
 c-0.2,0-0.5-0.1-1.2-0.4c-0.7-0.3-1.8-0.4-2.5-0.4c-1.5,0-4.6,0-4.6,2.5c0,1.2,1,2.1,2.8,2.5l3.9,0.9c1.1,0.3,1.7,0.9,1.7,1.6
 c0,1.5-1.7,1.8-3.2,1.8c-3.8,0-4.7-1.3-4.9-2.3V7.4h-0.5v2.6h0.5l0.1-0.1c0.1-0.2,0.2-0.4,0.7-0.4c0.3,0,0.5,0.1,0.9,0.2
 c0.7,0.2,1.7,0.5,3.5,0.5c4.1,0,4.7-1.8,4.7-2.8C78.5,5.6,76.6,5,74.9,4.6 M5.1,7.5C4.5,8.8,3.2,9.9,1.7,9.9c-1.3,0-1.8-1.4-1-2.7
 c1.2-2,1.6-2.7,3.2-5.3C3.9,1.9,4,1.8,4.3,1.8H5c0.2,0,0.3,0.1,0.2,0.4C5,2.4,3.8,4,3,5.3c0.3-0.2,0.7-0.4,1.2-0.4
 c1,0,1.3,0.6,1.3,1.5c0,0.2-0.1,0.4-0.1,0.5h0.2c0.9,0,1.5-0.6,1.9-1c0.2-0.2,0.3-0.2,0.4-0.1c0.1,0.2,0.1,0.4-0.2,0.6
 c-0.4,0.5-1.2,1-2.3,1C5.3,7.5,5.1,7.5,5.1,7.5z M1.9,9.3c0.8,0,1.8-1,2.3-2.2C4,7,3.9,6.8,3.9,6.5c0-0.4,0.2-0.6,0.4-0.8
 C4.2,5.6,4,5.6,3.9,5.6c-0.4,0-1.3,0.4-2,1.5c-0.2,0.3-0.4,0.6-0.5,1C0.9,8.8,1.1,9.3,1.9,9.3 M9.4,10.5c-1,1.9-2.4,3.9-4,3.9
 c-0.8,0-1.2-0.5-1.2-1.1c0-1,0.8-1.6,1.9-2.1c0.8-0.4,1.6-0.6,2.3-0.9L9,9c-0.5,0.5-1.1,1-1.7,1c-1,0-1.5-1-1-2
 c0.4-0.8,1.3-2.4,1.5-2.5c0.1-0.1,0.2-0.2,0.4-0.2h0.7C9,5.2,9.1,5.3,9,5.6c-0.2,0.2-1.2,2-1.6,2.8C7.1,9,7.3,9.2,7.6,9.2
 c0.3,0,0.8-0.3,1.5-1c0.7-0.8,1.6-2.2,1.8-2.5L11,5.4c0.1-0.1,0.2-0.2,0.4-0.2h0.7c0.2,0,0.3,0.1,0.2,0.4l-1.5,2.8
 c-0.2,0.4-0.4,0.9-0.7,1.3c1.3-0.6,2.2-1.2,3-2.1c0.2-0.3,0.3-0.2,0.4-0.1c0.1,0.2,0.1,0.4-0.1,0.6C12.5,8.9,11.1,9.7,9.4,10.5
  M7.8,11.1c-1.2,0.5-2.2,0.9-2.7,1.3c-0.6,0.5-0.4,1.3,0.3,1.3S7,12.6,7.8,11.1"
      />
    </svg>
  );
};

export const instagram = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 427 427"
    height="20px"
    width="20px"
    stroke="rgb(255, 255, 255)"
    fill="rgb(255, 255, 255)"
  >
    <path
      className="st0"
      d="M213.5 38.5c57 0 63.8.2 86.3 1.2 20.8 1 32.1 4.4 39.6 7.4 10 3.9 17.1 8.5 24.5 16 7.5 7.5 12.1 14.6 16 24.5 2.9 7.5 6.4 18.8 7.4 39.6 1 22.5 1.2 29.3 1.2 86.3s-.2 63.8-1.2 86.3c-1 20.8-4.4 32.1-7.4 39.6-3.9 10-8.5 17.1-16 24.5-7.5 7.5-14.6 12.1-24.5 16-7.5 2.9-18.8 6.4-39.6 7.3-22.5 1-29.3 1.2-86.3 1.2s-63.8-.2-86.3-1.2c-20.8-.9-32.1-4.4-39.6-7.3-10-3.9-17.1-8.5-24.5-16-7.5-7.5-12.1-14.6-16-24.5-2.9-7.5-6.4-18.8-7.4-39.6-1-22.5-1.2-29.3-1.2-86.3s.2-63.8 1.2-86.3c.9-20.8 4.4-32.1 7.4-39.6 3.9-10 8.5-17.1 16-24.5 7.5-7.5 14.6-12.1 24.5-16 7.5-2.9 18.8-6.4 39.6-7.4 22.5-1 29.3-1.2 86.3-1.2m0-38.5c-58 0-65.3.2-88 1.3-22.7 1-38.2 4.6-51.8 9.9-14 5.5-25.9 12.8-37.8 24.6C24 47.7 16.7 59.6 11.2 73.6c-5.3 13.6-8.9 29.1-9.9 51.8-1 22.8-1.3 30-1.3 88s.2 65.3 1.3 88c1 22.7 4.6 38.2 9.9 51.8 5.5 14 12.8 25.9 24.6 37.8 11.9 11.9 23.8 19.2 37.8 24.6 13.6 5.3 29.1 8.9 51.8 9.9 22.8 1 30 1.3 88 1.3s65.3-.2 88-1.3c22.7-1 38.2-4.6 51.8-9.9 14-5.5 25.9-12.8 37.8-24.6 11.9-11.9 19.2-23.8 24.6-37.8 5.3-13.6 8.9-29.1 9.9-51.8 1-22.8 1.3-30 1.3-88s-.2-65.3-1.3-88c-1-22.7-4.6-38.2-9.9-51.8-5.5-14-12.8-25.9-24.6-37.8-11.9-11.9-23.8-19.2-37.8-24.6-13.6-5.3-29.1-8.9-51.8-9.9C278.7.2 271.5 0 213.5 0"
    />
    <path
      className="st0"
      d="M213.5 103.9c-60.5 0-109.6 49.1-109.6 109.6S153 323.1 213.5 323.1 323.1 274 323.1 213.5c0-60.6-49.1-109.6-109.6-109.6m0 180.8c-39.3 0-71.2-31.9-71.2-71.2s31.9-71.2 71.2-71.2 71.2 31.9 71.2 71.2-31.9 71.2-71.2 71.2M353.1 99.5c0 14.1-11.5 25.6-25.6 25.6s-25.6-11.5-25.6-25.6c0-14.2 11.5-25.6 25.6-25.6s25.6 11.5 25.6 25.6"
    />
  </svg>
);
