import Modal from "../../widgets/Modal";
import useAppState from "../../providers/AppProvider.js";
import { _classes } from "../../utilities/helpers";
import { motion, AnimatePresence } from "../../utilities/FramerMotion";
import styles from "../../../styles/partials/header-components/NavBookingWidget.scss";
import CursiveText from "../../components/CursiveText";
import { Close } from "../../widgets/SVGs/Close";
import NavBookingWidgetForm from "./NavBookingWidgetForm";

export default function NavBookingWidget() {
  const { bookNowModal, setBookNowModal } = useAppState();
  const cl = _classes(styles);

  const variants = {
    initial: { x: "100%" },
    animate: {
      x: 0,
    },
    exit: {
      x: "100%",
    },
    transition: { type: "spring", bounce: 0, duration: 0.4 },
  };

  const toggleForm = () => {
    setBookNowModal(!bookNowModal);
  };

  return (
    <Modal visible={bookNowModal} close={() => toggleForm()}>
      <AnimatePresence>
        <motion.div id="booking-widget" className={cl("root")} {...variants}>
          <div className={cl("close")}>
            <button
              aria-label="close booking modal"
              type="button"
              onClick={(e) => {
                toggleForm()}
                }
              tabIndex="0"
              onKeyDown={(e) =>
                e.key === "Enter" || (e.key === " " && toggleForm())
              }
            >
              {Close()}
            </button>
          </div>

          <div className={cl("form")}>
            <CursiveText
              text="Enjoy your stay"
              fontSize="sm"
              fontColor="white"
              className={cl("title")}
            />
            <NavBookingWidgetForm />
          </div>
        </motion.div>
      </AnimatePresence>
    </Modal>
  );
}
