export const caretArrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 59.414 59.414"
    height="20px"
    width="20px"
  >
    <path
      fill="#fff"
      d="M29.707 45.268 0 15.561l1.414-1.415 28.293 28.293L58 14.146l1.414 1.415z"
      strokeWidth="3px"
    />
  </svg>
);
