import { Controller } from "react-hook-form/dist/index.ie11";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import Label from "../Label/Label";
import { useFormContext } from "../FormProvider";
import { v4 as uuidv4 } from "uuid";
import styles from "./StandardInput.scss";
import { _classes } from "../../utilities/helpers";

const UUID = uuidv4();

const cl = _classes(styles);

StandardInput.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  rules: PropTypes.object,
  defaultValue: PropTypes.string,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  errorClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  disabled: PropTypes.bool,
  middleware: PropTypes.func,
  labelVisible: PropTypes.bool,
};

StandardInput.defaultProps = {
  name: "input",
  label: "Label",
  placeholder: "",
  type: "text",
  defaultValue: "",
  className: "",
  inputClassName: "",
  errorClassName: "",
  labelClassName: "",
  middleware: (value) => value,
};

export default function StandardInput({
  name,
  placeholder,
  type,
  label,
  rules,
  defaultValue,
  disabled,
  middleware,
  labelVisible,
  className,
  inputClassName,
  errorClassName,
  labelClassName,
}) {
  const { control, errors } = useFormContext();
  const id = `${name}__${UUID}`;
  // console.log("control", control);
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({ onChange, value = "" }) => (
        <div
          className={cl(["root", className])}
          style={{
            display: type === "hidden" ? "none" : "block",
          }}
        >
          {labelVisible && (
            <Label
              value={label}
              htmlFor={id}
              className={cl(["label", labelClassName])}
            />
          )}
          <div className={cl("control")}>
            <input
              id={id}
              disabled={disabled}
              onChange={(e) => onChange(middleware(e.target.value))}
              type={type}
              name={name}
              value={value}
              placeholder={placeholder}
              className={cl(["input", inputClassName])}
            />
          </div>
          <div className={cl("error")}>
            <ErrorMessage error={errors[name]} className={errorClassName} />
          </div>
        </div>
      )}
    />
  );
}
