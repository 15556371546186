import styles from "../../styles/partials/FooterPropertyBranding.scss";
import { parse, _classes } from "../utilities/helpers";
import { useAppState } from "../providers";
import { Link } from "../widgets";
const cl = _classes(styles);

FooterPropertyBranding.propTypes = {
  pageContent: PropTypes.object,
  className: PropTypes.string,
};

export default function FooterPropertyBranding({ pageContent }) {
  const { byTemplate } = useAppState();
  const footerContent = byTemplate("footer")[0];

  return (
    <div className={cl("_")}>
      <Link
        className={cl("section")}
        path={pageContent.maps_url}
        target="_blank"
      >
        <span>{pageContent.address1}</span>
        <span>{pageContent.address2}</span>
        <span>{pageContent.postal_code}</span>
      </Link>

      <div className={cl("section")}>
        <a href={parse(footerContent?.link).link || "/"}>
          <img className={cl("image")} src={footerContent?.image1} />
        </a>
      </div>
      <div className={cl("section")}>
        <span>
          Telephone:{" "}
          <a href={`tel:${pageContent?.phone}`}>{pageContent?.phone}</a>
        </span>
        <Link path="/contact-us">
          <span className={cl("contact-us-link")}>Contact Us</span>
        </Link>
      </div>
    </div>
  );
}
