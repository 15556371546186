import ErrorMessage from "../ErrorMessage/ErrorMessage";
import Label from "../Label/Label";
import { Controller } from "react-hook-form/dist/index.ie11";
import moment from "moment";
import { addDays, format } from "date-fns";
import { UseFormMethods } from "react-hook-form/dist/index.ie11";
import { useFormContext } from "../FormProvider";
import { v4 as uuidv4 } from "uuid";
import { CalendarArrow } from "../../widgets/SVGs/CalendarArrow";
import styles from "./DayPickerInput.scss";
import { _classes } from "../../utilities/helpers";
import { DayPicker } from "react-day-picker";
import {
  forwardRef,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useViewportContext } from "../../providers";

const cl = _classes(styles);
const UUID = uuidv4();

DayPickerInput.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  rules: PropTypes.object,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  errorClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  dayPickerClassName: PropTypes.string,
  defaultValue: PropTypes.string,
  align: PropTypes.oneOf(["left", "right"]),
  dayPickerProps: PropTypes.object,
};

DayPickerInput.defaultProps = {
  name: "date",
  format: "LL",
  dayPickerProps: {},
  className: "",
  inputClassName: "",
  errorClassName: "",
  labelClassName: "",
  dayPickerClassName: "",
  align: "",
  dayPickerProps: {},
};

export default function DayPickerInput({
  name,
  placeholder,
  label,
  rules,
  className,
  inputClassName,
  errorClassName,
  labelClassName,
  dayPickerClassName,
  defaultValue,
  align,
  dayPickerProps,
  openingDate
}) {
  const inputRef = useRef();
  const isMobile = useViewportContext().viewport.is("xsmall");

  /** @type {UseFormMethods} */
  const { control, errors, setValue, getValues, watch } = useFormContext();
  const watchedFormValue = watch(name);

  const id = `${name}__${UUID}`;

  const [menuVisible, setMenuVisible] = useState(false);
  const [didFormChange, setDidFormChange] = useState(false);
  const [didInteract, setDidInteract] = useState(false);

  React.useEffect(() => {
    // if (menuVisible) {
    inputRef.current &&
      inputRef.current &&
      inputRef.current.parentElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    // }
  }, [inputRef]);

  const isDescendant = function (parent, child) {
    let node = child?.parentNode;
    while (node) {
      if (node === parent) return true;
      node = node.parentNode;
    }
    return false;
  };

  const ref = useRef();
  const [selectedDay, setSelectedDay] = useState(defaultValue);

  useEffect(() => {
    // add +1 day to the selected day to set the depart date(only if the depart date is not already set)
    // setValue(name, selectedDay);
    if (
      (!didFormChange && name === "arrive") ||
      (name === "arrive" && selectedDay >= getValues("depart"))
    ) {
      setValue("arrive", selectedDay);
      setValue("depart", addDays(selectedDay, 1));
    } else {
      setValue(name, selectedDay);
    }
    setDidFormChange(false);
    // mark if the user has interacted with the date picker, so we can show the proper month in the calendar when the user clicks on the input again(otherwise shows the initial month)
    setDidInteract(true);
    // menuVisible && setMenuVisible(false);
  }, [selectedDay]);

  const handleDayClick = (day, { selected }, e) => {
    setSelectedDay(day);
    setDidFormChange(true);
  };

  useEffect(() => {
    setMenuVisible(false);
  }, [didFormChange]);

  useEffect(() => {
    setSelectedDay(watchedFormValue);
  }, [menuVisible]);

  const disabledDays = name === "depart" && [
    {
      before: moment(getValues("arrive")).add(1, "day").toDate(),
    },
  ];

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({ onChange, value, name }) => {
        // ex: isMobile ? "Jul 1, 2021" : "July 1, 2021
        const formattedValue = moment(value).format(
          isMobile ? "MMM DD, YYYY" : "MMMM DD, YYYY"
        );
        return (
          <div className={cl(["root", className])} ref={ref}>
            <Label
              value={label}
              htmlFor={id}
              className={cl(["label", labelClassName])}
              tabIndex={-1}
            />
            <div
              className={cl("control")}
              // set the menuVisible to false when the user clicks outside of the date picker
              // setting a tabIndex on the div allows the div to be focused, so we can listen for the blur event.
              tabIndex={0}
              onBlur={(e) => {
                !isDescendant(ref.current, e.relatedTarget) &&
                  setMenuVisible(false);
              }}
            >
              <input
                id={id}
                onInput={(e) => {
                  // setMenuVisible((pv) => !pv);
                }}
                type={"text"}
                readOnly
                inputMode="none"
                name={name}
                value={formattedValue}
                placeholder={placeholder}
                className={cl(["input", inputClassName])}
                onKeyDown={() => {
                  setMenuVisible(true);
                }}
                onClick={() => {
                  setMenuVisible(true);
                }}
                onChange={onChange}
              />
              <div
                // for setting the menuVisible to false when the user clicks outside of the date picker
                // stopPropagation is needed to prevent the blur event from firing on the div when the user clicks on the calendar
                onBlur={(e) => {
                  e.stopPropagation();
                }}
              >
                {menuVisible && (
                  <div>
                    <DayPicker
                      modifiers={{
                        selected: selectedDay,
                      }}
                      modifiersStyles={{
                        selected: {
                          backgroundColor: "#1d5c39",
                          color: "#fff",
                          fontWeight: "bold",
                        },
                      }}
                      onDayFocus={setMenuVisible}
                      // always highlight the selected depart date
                      selectedDays={selectedDay}
                      mode="single"
                      selected={selectedDay}
                      onDayClick={handleDayClick}
                      // this does not change the input value, only the selected day for the visual display on the calendar
                      onSelect={setSelectedDay}
                      className={cl(["daypicker", align, dayPickerClassName])}
                      // needed to keep track of the selected day when opening the date picker again(otherwise the selected day will be reset to the default value)
                      fromDate={
                        name === "depart"
                          ? getValues("arrive")
                          : openingDate || new Date()
                      }
                      defaultMonth={
                        !didInteract ? openingDate : watchedFormValue
                      }
                      //
                      disabled={disabledDays}
                      // any props passed to the DayPickerInput will be passed to the DayPicker component, overriding the default props above
                      {...dayPickerProps}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className={cl("error")}>
              <ErrorMessage error={errors[name]} className={errorClassName} />
            </div>
          </div>
        );
      }}
    />
  );
}

NavbarElement.propTypes = {
  month: PropTypes.string,
  onNextClick: PropTypes.func,
  onPreviousClick: PropTypes.func,
};

function NavbarElement({ month, onNextClick, onPreviousClick }) {
  return (
    <div className={"DayPicker-Caption-Custom"}>
      <button
        type="button"
        onClick={() => onPreviousClick(null)}
        className={"DayPicker-Arrow previous"}
      >
        <CalendarArrow />
      </button>
      <span>{value(month).format("MMMM YYYY")}</span>
      <button
        type="button"
        onClick={() => onNextClick(null)}
        className={"DayPicker-Arrow next"}
      >
        <CalendarArrow />
      </button>
    </div>
  );
}
